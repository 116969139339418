import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomPurpleButton from "../../CustomComponents/CustomPurpleButton";
import FullHeightCenter from "../../FullHeightCenter";
import { getRoutePath } from "../../routes";

function NotFoundPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const handleBackClick = () => {
    history.push(getRoutePath("home"));
  };

  return (
    <FullHeightCenter>
      <Box p={2}>
        <Typography variant="h3" component="p" gutterBottom>
          {t("404.title")}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t("404.text")}
        </Typography>
        <Box mt={2}>
          <CustomPurpleButton onClick={handleBackClick}>
            {t("404.button.text")}
          </CustomPurpleButton>
        </Box>
      </Box>
    </FullHeightCenter>
  );
}

export default NotFoundPage;
