/**
 * Liste des rôles présent dans l'application
 */
const appRoles = {
  SUPERVISOR: "SUPERVISOR",
  ADMIN: "ADMIN",
  ADMIN_TEMPLATE: "ADMIN_TEMPLATE",
  ADMIN_EVENT: "ADMIN_EVENT",
};

const isInRoles = (userRoles, rolesToCheck) => {
  return rolesToCheck.some((r) => userRoles.includes(r));
};

export { appRoles, isInRoles }

