import MomentUtils from "@date-io/moment";
import { CircularProgress } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConfirmProvider } from "material-ui-confirm";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { CustomThemeContext } from "../contexts/CustomThemeContext";
import { UserContextProvider } from "../contexts/UserContext";
import "../styles/global.scss";
import FullHeightCenter from "./FullHeightCenter";
import { routes } from "./routes";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";

function App() {
  const { t, i18n } = useTranslation();
  const routeComponents = routes.map(({ exact, path, component }, key) => (
    <Route exact={exact} path={path} component={component} key={key} />
  ));

  return (
    <Suspense
      fallback={
        <FullHeightCenter>
          <CircularProgress />
        </FullHeightCenter>
      }
    >
      <CustomThemeContext.Consumer>
        {(context) => (
          <>
            <Helmet htmlAttributes={{ lang: i18n.language }}>
              <meta
                name="theme-color"
                content={context.theme.palette.background.default}
              />
            </Helmet>
            <UserContextProvider>
              <ThemeProvider theme={context.theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ConfirmProvider
                    defaultOptions={{
                      title: t("common.confirm.dialog.title"),
                      confirmationText: t("common.confirm.dialog.button.ok"),
                      cancellationText: t("common.confirm.dialog.button.nok"),
                    }}
                  >
                    <Switch>
                      {routeComponents}
                      <Route component={NotFoundPage} />
                    </Switch>
                  </ConfirmProvider>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </UserContextProvider>
          </>
        )}
      </CustomThemeContext.Consumer>
    </Suspense>
  );
}

export default App;
