import React from "react";
import { appRoles } from "../helpers/rolesHelper";
import Auth from "../hoc/auth";
const AppMailTemplatesPage = React.lazy(() =>
  import("./views/Admin/AppMailTemplatesPage/AppMailTemplatesPage")
);
const AppParameterPage = React.lazy(() =>
  import("./views/Admin/AppParameterPage/AppParameterPage")
);
const ChampFusionPage = React.lazy(() =>
  import("./views/Admin/ChampFusionPage/ChampFusionPage")
);
const ConfigurationPage = React.lazy(() =>
  import("./views/Admin/ConfigurationPage/ConfigurationPage")
);
const EventTemplates = React.lazy(() =>
  import("./views/Admin/EventTemplate/EventTemplates")
);
const EventTypesPage = React.lazy(() =>
  import("./views/Admin/EventTypesPage/EventTypesPage")
);
const LegalMentionPageAdmin = React.lazy(() =>
  import("./views/Admin/LegalMentionPage/LegalMentionPageAdmin")
);
const MailTestPage = React.lazy(() =>
  import("./views/Admin/MailTestPage/MailTestPage")
);
const GeneralInfosPage = React.lazy(() =>
  import("./views/Admin/NewEventTemplate/GeneralInfos/GeneralInfosPage")
);
const Guests = React.lazy(() =>
  import("./views/Admin/NewEventTemplate/Guests/Guests")
);
const Inscriptions = React.lazy(() =>
  import("./views/Admin/NewEventTemplate/Inscriptions/Inscriptions")
);
const InvitationPhasePage = React.lazy(() =>
  import("./views/Admin/NewEventTemplate/InvitationPhase/InvitationPhasePage")
);

const EventTemplatePreviewPage = React.lazy(() =>
  import("./views/Admin/NewEventTemplate/PreviewPage/EventTemplatePreviewPage")
);
const SmsParametersPage = React.lazy(() =>
  import("./views/Admin/SmsParametersPage/SmsParametersPage")
);
const TemplatesPage = React.lazy(() =>
  import("./views/Admin/TemplatesPage/TemplatesPage")
);
const UsersPage = React.lazy(() => import("./views/Admin/UsersPage/UsersPage"));
const DashboardPage = React.lazy(() =>
  import("./views/DashboardPage/DashboardPage")
);
const ToPublishEventsPage = React.lazy(() =>
  import("./views/DashboardPage/ToPublishEventsPage")
);
const EventPage = React.lazy(() => import("./views/EventPage/EventPage"));
const EventLayout = React.lazy(() => import("./views/Events/EventLayout"));
const LegalMentionPage = React.lazy(() =>
  import("./views/LegalMentionPage/LegalMentionPage")
);
const ForgotPassword = React.lazy(() =>
  import("./views/LoginPage/ForgotPassword")
);
const ForgotPasswordReset = React.lazy(() =>
  import("./views/LoginPage/ForgotPasswordReset")
);
const LoginCFA = React.lazy(() => import("./views/LoginPage/LoginCFA.js"));
const LoginPage = React.lazy(() => import("./views/LoginPage/LoginPage.js"));
const TrackingPage = React.lazy(() =>
  import("./views/TrackingPage/TrackingPage")
);

/**
 * Réference toutes les routes de l'application
 */
export const routes = [
  { exact: true, name: "index", path: "/", component: Auth(null, true) },

  // Connexion
  {
    exact: true,
    name: "login",
    path: "/login",
    component: Auth(LoginPage, false),
  },
  {
    exact: true,
    name: "forgot-password",
    path: "/forgot-password",
    component: Auth(ForgotPassword, false),
  },
  {
    exact: true,
    name: "forgot-password-reset/:token",
    path: "/forgot-password-reset/:token",
    component: Auth(ForgotPasswordReset, false),
  },
  {
    exact: true,
    name: "login-cfa",
    path: "/login-cfa",
    component: Auth(LoginCFA, null),
  },

  // Page d'un évènement sans eventuserid
  {
    exact: true,
    name: "event-page",
    path: "/public/event/:id/:userid?",
    component: Auth(EventPage, null),
  },

  // Page d'un évènement avec eventuserid
  {
    exact: true,
    name: "event-page-with-eventuserid",
    path: "/public/event/:id/:userid/:eventuserid?",
    component: Auth(EventPage, null),
  },

  // Formulaire de suivi
  {
    exact: true,
    name: "tracking-page",
    path: "/public/tracking/:id/:userid/:eventuserid?",
    component: Auth(TrackingPage, null),
  },

  // Dashboard
  {
    exact: true,
    name: "home",
    path: "/home",
    component: Auth(DashboardPage, true),
  },

  // Mes brouillons
  {
    exact: true,
    name: "to-publish-events",
    path: "/to-publish-events",
    component: Auth(ToPublishEventsPage, true),
  },

  {
    exact: true,
    name: "templates",
    path: "/templates",
    component: Auth(TemplatesPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },

  // Création de templates évènements
  // Grille
  {
    exact: true,
    name: "event-templates",
    path: "/admin/event-templates",
    component: Auth(EventTemplates, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  // Step 1
  {
    exact: true,
    name: "admin-new-event-template-general-infos",
    path: "/admin/new-event-template/general-infos/:id?",
    component: Auth(GeneralInfosPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  // Step 2
  {
    exact: true,
    name: "new-event-template-inscriptions",
    path: "/new-event-template/inscriptions/:id",
    component: Auth(Inscriptions, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  // Step 3
  {
    exact: true,
    name: "admin-new-event-template-guests",
    path: "/new-event-template/guests/:id",
    component: Auth(Guests, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },

  // Step 4
  {
    exact: true,
    name: "new-event-template-invitation-phase",
    path: "/new-event-template-invitations/:id",
    component: Auth(InvitationPhasePage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  {
    exact: true,
    name: "new-event-template-preview",
    path: "/new-event-template/preview/:id",
    component: Auth(EventTemplatePreviewPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  {
    exact: true,
    name: "admin-event-types",
    path: "/admin/event-types",
    component: Auth(EventTypesPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  // Création d'évènements
  {
    exact: true,
    name: "event",
    path: "/event/:id",
    component: Auth(EventLayout, true),
  },

  {
    exact: true,
    name: "legal-mention",
    path: "/legal-mention",
    component: Auth(LegalMentionPage, true),
    // Templates
  },
  // Admin
  {
    exact: true,
    name: "admin-legal-mention",
    path: "/admin/legal-mention",
    component: Auth(LegalMentionPageAdmin, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
    ]),
  },
  {
    exact: true,
    name: "users",
    path: "/admin/users",
    component: Auth(UsersPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
      appRoles.ADMIN_EVENT,
      appRoles.ADMIN_TEMPLATE,
    ]),
  },
  {
    exact: true,
    name: "sms-parameter",
    path: "/admin/sms-parameter",
    component: Auth(SmsParametersPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
    ]),
  },
  {
    exact: true,
    name: "app-parameter",
    path: "/admin/app-parameter",
    component: Auth(AppParameterPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
    ]),
  },
  {
    exact: true,
    name: "app-mail-templates",
    path: "/admin/app-mail-templates",
    component: Auth(AppMailTemplatesPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
    ]),
  },
  {
    exact: true,
    name: "configuration",
    path: "/admin/configuration",
    component: Auth(ConfigurationPage, true, [
      appRoles.SUPERVISOR,
      appRoles.ADMIN,
    ]),
  },
  {
    exact: true,
    name: "fusion-field",
    path: "/supervisor/fusion-field",
    component: Auth(ChampFusionPage, true, [appRoles.SUPERVISOR]),
  },
  {
    exact: true,
    name: "test-mail",
    path: "/supervisor/test-mail",
    component: Auth(MailTestPage, true, [appRoles.SUPERVISOR]),
  },
];

/**
 * Retourne le path d'une route
 * @param {string} name Le nom de la route a récupérer
 * @param {object} toReplace Un objet contenant les paramètres de la route à remplacer au format
 * @returns Le path de la route demandée
 * @example getRoutePath("user", { ":id": "17" }) => "/user/17"
 */
export const getRoutePath = (name, toReplace) => {
  const filtered = routes.filter((r) => r.name === name);
  if (filtered.length === 0) return null;

  let path = filtered[0].path;
  if (toReplace) {
    let keys = Object.keys(toReplace);
    for (let i = 0; i < keys.length; i++) {
      path = path.replace(keys[i], toReplace[keys[i]]);
    }
  }

  return path ?? "/";
};
