import axios from "axios";
import { CONFIGURATION_SERVER } from "../components/Config.js";
import { postWithAuth } from "./auth_actions";

export function getConfiguration() {
  return axios.post(`${CONFIGURATION_SERVER}`, {}).then((r) => r.data);
}

export function updateConfiguration(configId, dataToSubmit) {
  return postWithAuth(
    `${CONFIGURATION_SERVER}/update/${configId}`,
    dataToSubmit
  ).then((r) => r.data);
}
