import { Mutex } from "async-mutex";
import axios from "axios";
import moment from "moment";
import { USER_SERVER } from "../components/Config.js";

const lstoragetoken = "token";
export function storeToken(token) {
  localStorage.setItem(lstoragetoken, token);
}

export function getToken() {
  return localStorage.getItem(lstoragetoken);
}

export function dropToken() {
  localStorage.removeItem(lstoragetoken);
}

export function withAuth(opt) {
  const authHeader = {
    Authorization: `Bearer ${getToken()}`,
  };

  var toret = opt;
  if (toret) {
    toret.withCredentials = true;
    toret.headers = { ...opt.headers, ...authHeader };
  } else {
    toret = {
      withCredentials: true,
      headers: authHeader,
    };
  }

  return toret;
}

const mutex = new Mutex();
export function authUser() {
  return mutex.runExclusive(() => {
    return axios
      .post(`${USER_SERVER}/auth`, null, withAuth())
      .then((response) => {
        if (!response.data.error) {
          storeAuth(response.data);
          storeToken(response.data.user.jwtToken);
        }
        return response.data;
      });
  });
}

export function newToken() {
  return axios
    .post(`${USER_SERVER}/new-token`, null, withAuth())
    .then((response) => {
      if (!response.data.error) {
        storeAuth(response.data);
        storeToken(response.data.user.jwtToken);
      }
      return response.data;
    });
}

export function registerUser(dataToSubmit) {
  return axios
    .post(`${USER_SERVER}/register`, dataToSubmit, { withCredentials: true })
    .then((response) => {
      if (!response.data.error) {
        storeAuth(response.data);
        storeToken(response.data.user.jwtToken);
      }
      return response.data;
    });
}

export function cfaSSO(token) {
  return axios
    .post(`${USER_SERVER}/validate-token-ypareo/${token}`, null, {
      withCredentials: true,
    })
    .then((response) => {
      if (!response.data.error) {
        storeAuth(response.data);
        storeToken(response.data.user.jwtToken);
      }
      return response.data;
    });
}

export function loginUser(dataToSubmit) {
  return axios
    .post(`${USER_SERVER}/login`, dataToSubmit, { withCredentials: true })
    .then((response) => {
      if (!response.data.error) {
        storeAuth(response.data);
        storeToken(response.data.user.jwtToken);
      }
      return response.data;
    });
}

export function logoutUser() {
  return postWithAuth(`${USER_SERVER}/logout`).then((response) => {
    logoff();

    return response.data;
  });
}

const handleLogoffIfNotConnected = (
  response,
  resolve,
  bypassRedirect = false
) => {
  if (bypassRedirect) return;
  if (response.isAuth === false) {
    logoff();
    window.location.href = "/login";
    resolve();
    return;
  }
};
const logoff = () => {
  storeAuth(null);
  dropToken();
};

const tempAuth = {
  auth: null,
  timeout: null,
};

const storeAuth = (toStore) => {
  tempAuth.auth = toStore;
  tempAuth.timeout = moment().add(4, "s").toDate();
};

const mutex2 = new Mutex();
export function auth() {
  return mutex2.runExclusive(() => {
    return new Promise((resolve, reject) => {
      if (tempAuth && tempAuth.timeout > new Date()) {
        resolve(tempAuth.auth);
      } else {
        authUser().then((auth) => {
          if (!auth.needANewToken) {
            resolve(auth);
          } else {
            newToken().then((resp) => {
              if (!resp.error) {
                resolve(resp);
              } else {
                reject(resp);
              }
            });
          }
        });
      }
    });
  });
}

export function postWithAuth(url, data, opt, bypassRedirect = false) {
  return new Promise((resolve) => {
    auth().then((r) => {
      handleLogoffIfNotConnected(r, resolve, bypassRedirect);
      axios
        .post(url, data, withAuth(opt))
        .then((response) => resolve(response));
    });
  });
}

export async function getWithAuth(url, opt, bypassRedirect = false) {
  return new Promise((resolve) => {
    auth().then((r) => {
      handleLogoffIfNotConnected(r, resolve, bypassRedirect);
      return axios
        .get(url, withAuth(opt))
        .then((response) => resolve(response));
    });
  });
}

export async function deleteWithAuth(url, opt, bypassRedirect = false) {
  return new Promise((resolve) => {
    auth().then((r) => {
      handleLogoffIfNotConnected(r, resolve, bypassRedirect);
      axios.delete(url, withAuth(opt)).then((response) => resolve(response));
    });
  });
}

export async function putWithAuth(url, data, opt, bypassRedirect = false) {
  return new Promise((resolve) => {
    auth().then((r) => {
      handleLogoffIfNotConnected(r, resolve, bypassRedirect);
      axios.put(url, data, withAuth(opt)).then((response) => resolve(response));
    });
  });
}
