//SERVER ROUTES
export const SERVER_URL = (process.env.REACT_APP_API_URL ?? "") + "/api";
export const APP_PARAMETER_SERVER = SERVER_URL + "/appparameter";
export const USER_SERVER = SERVER_URL + "/user";
export const CONFIGURATION_SERVER = SERVER_URL + "/configuration";
export const DATA_SERVER = SERVER_URL + "/data";
export const EVENT_TEMPLATE_SERVER = SERVER_URL + "/eventtemplate";
export const EVENT_SERVER = SERVER_URL + "/event";
export const EVENT_USER_SERVER = SERVER_URL + "/eventuser";
export const LEGAL_MENTION_SERVER = SERVER_URL + "/legalmention";
export const SMS_TEMPLATE_SERVER = SERVER_URL + "/smstemplate";
export const SMS_TEMPLATE_HISTORISATION_SERVER =
  SERVER_URL + "/smstemplatehistorisation";
export const CHAMP_FUSION_SERVER = SERVER_URL + "/champfusion";
export const DOCUMENT_TEMPLATE_SERVER = SERVER_URL + "/documenttemplate";
export const DOCUMENT_TEMPLATE_HISTORISATION_SERVER =
  SERVER_URL + "/documenttemplatehistorisation";
export const MAIL_TEMPLATE_SERVER = SERVER_URL + "/mailtemplate";
export const MAIL_TEMPLATE_HISTORISATION_SERVER =
  SERVER_URL + "/mailtemplatehistorisation";
export const FORM_TEMPLATE_SERVER = SERVER_URL + "/formtemplate";
export const FORM_TEMPLATE_HISTORY_SERVER = SERVER_URL + "/formtemplatehistory";
export const TIME_SLOT_SERVER = SERVER_URL + "/timeslot";
export const TRACKING_SERVER = SERVER_URL + "/tracking";
export const SMS_PARAMETERS_SERVER = SERVER_URL + "/smsparameters";
export const DASHBOARD_SERVER = SERVER_URL + "/dashboard";
export const HISTORY_EVENT_ACTIONS = SERVER_URL + "/historyevent";
export const APP_MAIL_TEMPLATE_SERVER = SERVER_URL + "/appmailtemplate";
export const MAIL_SERVER = SERVER_URL + "/mail";
export const EVENT_TYPE_SERVER = SERVER_URL + "/eventtype";
