import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadiusInput,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));
function CustomPurpleButton({ children, className = "", ...props }) {
  // const [buttonProps, setButtonProps] = React.useState(props)
  // React.useEffect(() => {
  //   setButtonProps(props)
  // }, [props])
  const classes = useStyles();

  return (
    <Button
      className={`${classes.button} ${className}`}
      variant="contained"
      color="primary"
      {...props}
    >
      {children}
    </Button>
  );
}

export default CustomPurpleButton;
