import { frFR } from "@material-ui/core/locale";
import { createMuiTheme } from "@material-ui/core/styles";
import React from "react";
import muiTheme from "../styles/theme";
import { getConfiguration } from "../_actions/configuration_actions.js";

const CustomThemeContext = React.createContext();
const defaultPrimary = "#5F4A8C";
const defaultSecondary = "#5b526d";

const types = ["light", "dark"];
const defaultType = types[0];

function CustomThemeProvider({ children }) {
  const [id, setId] = React.useState(null);
  const [nameApp, setName] = React.useState("");
  const [primary, setPrimary] = React.useState(defaultPrimary);
  const [secondary, setSecondary] = React.useState(defaultSecondary);
  const [favicon, setFavicon] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [mediaURL, setMediaURL] = React.useState("");
  const [theme, setTheme] = React.useState(createMuiTheme(muiTheme, frFR));
  const [loading, setLoading] = React.useState(true);
  const logoCCCA = process.env.PUBLIC_URL + "/images/logo.png";
  React.useEffect(() => {
    getConfiguration().then((r) => {
      setId(r.id);
      setName(r.name);
      setPrimary(r.primaryColor);
      setSecondary(r.secondaryColor);
      setFavicon(r.faviconURL);
      setLogo(r.logoURL);
      setMediaURL(r.mediaURL);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    updateTheme(primary, secondary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary, secondary]);

  React.useEffect(() => {
    const DOMFavicon = document.getElementById("favicon");
    DOMFavicon.href = favicon;
  }, [favicon]);

  const updateTheme = (
    primaryColor,
    secondaryColor,
    type = window.localStorage.getItem("theme-type")
  ) => {
    if (!type || types.indexOf(type) === -1) type = defaultType;

    let newTheme = { ...muiTheme };
    const newPrimaryColor = primaryColor ?? newTheme.palette.primary.main;
    const newSecondaryColor = secondaryColor ?? newTheme.palette.secondary.main;
    const isDark = type === "dark";

    newTheme.palette.type = type;
    newTheme.palette.primary.main = newPrimaryColor;
    newTheme.palette.secondary.main = newSecondaryColor;

    newTheme = createMuiTheme(newTheme, frFR);

    newTheme.overrides.MuiTabs.root.borderBottom = `1px solid ${
      isDark ? theme.palette.grey["700"] : theme.palette.grey["400"]
    }`;

    window.localStorage.setItem("theme-type", type);
    setTheme(newTheme);
  };

  const updateName = (name) => {
    setName(name ?? nameApp);
  };

  const updateLogo = (logoUrl) => {
    setLogo(logoUrl ?? logo);
  };

  const updateFavicon = (faviconUrl) => {
    setFavicon(faviconUrl ?? favicon);
  };

  const updateColors = (primary, secondary) => {
    setPrimary(primary ?? defaultPrimary);
    setSecondary(secondary ?? defaultSecondary);
  };

  // On ne veut pas afficher la page avant d'avoir récupérer le theme
  if (loading) return null;
  return (
    <CustomThemeContext.Provider
      value={{
        id: id,
        name: nameApp,
        primary: primary,
        secondary: secondary,
        favicon: favicon,
        logo: logo,
        theme: theme,
        updateName: updateName,
        updateTheme: updateTheme,
        updateLogo: updateLogo,
        updateFavicon: updateFavicon,
        updateColors: updateColors,
        mediaURL,
      }}
    >
      {children}
    </CustomThemeContext.Provider>
  );
}

export { CustomThemeProvider, CustomThemeContext };
